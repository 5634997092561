require('./frontend_bootstrap');
window.Vue = require('vue');
require('./Helpers/filter');
require('./Helpers/Frontend/mixin');
require('./Helpers/Frontend/plugin');
require('./Helpers/Frontend/crud');

// ===============shopping cart=============
import store from "./vuex/store";

// ===============router=============
import router from "./Router/frontend_router.js";

Vue.component('app', require('./components/FrontendApp.vue').default);
// ===============app===============
const app = new Vue({
    el: '#app',
    router,
    // shopCart: shopCart,
    store: store,
    data: {
        innerWidth: window.innerWidth,
        baseurl: laravel.baseurl,
        loader: false,
        spinner: false,
        isActive: false,
        loggedIn: false,
        userimage: laravel.baseurl + '/public/images/user.png',
        menus_all: [],
        menus: [],
        categories: [],
        config: [],
    },
    methods: {
        getMenus: function () {
            axios
                .get("/categories")
                .then(res => {
                    this.menus = res.data.menus;
                    this.menus_all = res.data.menus_all;
                    this.categories = res.data.categories;
                    this.config = res.data.config;
                })
                .catch(error => console.log());
        }

    },
    mounted() {
        this.loggedIn = User.loggedIn();
        this.getMenus();
    },
    created() {
        // active parent menu
        $('.nav-item').children('.nav-link').removeClass('parent-active')
        setTimeout(() => {
            $('.router-link-exact-active').parents('.dropdown').siblings('#navbarDropdown').addClass('parent-active');
        }, 3000);
    }
});
