import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// ========== define route==========
const routes = [
    {
        path: '/', component: () => import('./../views/frontend/Layout'),
        // chlidren menu
        children: [
            { path: '/', name: 'home.page', component: () => import('./../views/frontend/Home') },
            // ------------------Content portion------------------
            { path: '/content/:slug', name: 'content', component: () => import('./../views/frontend/Content') },
            { path: '/contact-us', name: 'home.contactus', component: () => import('./../views/frontend/ContactUs') },
            // ------------------product portion------------------
            { path: '/products/:slug', name: 'home.products', component: () => import('./../views/frontend/product/Products') },
            { path: '/type-of-product/:slug', name: 'home.types', component: () => import('./../views/frontend/product/Products') },
            { path: '/search/:query', name: 'home.search', component: () => import('./../views/frontend/product/Products') },
            { path: '/details/:slug', name: 'home.productDetails', component: () => import('./../views/frontend/product/ProductDetails') },
            { path: '/user/login', name: 'user.login', component: () => import('./../views/frontend/Login/Login') },

            { /*==========user portion============*/
                path: '/user',
                component: () => import('./../views/frontend/user/Layout'),
                children: [
                    { path: '/user/dashboard', name: 'user.dashboard', component: () => import('./../views/frontend/user/Dashboard'), beforeEnter: authGuard },
                    { path: '/user/checkout', name: 'user.checkout', component: () => import('./../views/frontend/user/Checkout'), beforeEnter: authGuard },
                    { path: '/user/edit-profile', name: 'user.editProfile', component: () => import('./../views/frontend/user/EditProfile'), beforeEnter: authGuard },
                    { path: '/user/my-orders', name: 'user.myOrders', component: () => import('./../views/frontend/user/MyOrders'), beforeEnter: authGuard },
                    { path: '/user/invoice/:id', name: 'user.orderView', component: () => import('./../views/frontend/user/ViewOrder'), beforeEnter: authGuard },
                    { path: '/user/my-wishlists', name: 'user.myWishlists', component: () => import('./../views/frontend/user/MyWishlists'), beforeEnter: authGuard },
                    { path: '/user/address-book', name: 'user.addressBook', component: () => import('./../views/frontend/user/AddressBook'), beforeEnter: authGuard },
                ]
            },

            /*------SSL Payment Status--------*/
            { path: '/payment-success', component: () => import('./../views/frontend/SSL/Success') },
            { path: '/payment-cancel', component: () => import('./../views/frontend/SSL/Cancel') },
            { path: '/payment-failed', component: () => import('./../views/frontend/SSL/Failed') }
        ],
    },

]

// ========== define router==========
const router = new VueRouter({
    routes,
    hashbang: false,
    mode: 'history',
    base: process.env.MIX_VUE_ROUTER_BASE,
    linkActiveClass: "active",
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

// ==========check login or not==========
function authGuard(to, from, next) {
    if (User.loggedIn()) {
        next();
    } else {
        next({ name: 'user.login' });
    }
}


router.beforeEach((to, from, next) => {
    next();
    // active parent menu
    setTimeout(() => {
        $('.nav-item').children('.nav-link').removeClass('parent-active')
        $('.router-link-exact-active').parents('.dropdown').siblings('#navbarDropdown').addClass('parent-active');
    }, 100);
})

export default router
