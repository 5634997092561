<template>
  <div>
    <FlashMessage></FlashMessage>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  methods: {
    loginCheck() {
      axios
        .get("/user/login-check")
        .then((res) => {
          if (res.status == 200) {
            User.login(res.data);
          } else {
            if (User.loggedIn()) {
              User.logout();
            }
          }
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.loginCheck();
  },
  created() {
    AOS.init({
      duration: 1200,
      easing: "ease-out-quad",
      startEvent: "load",
    });
  },
};
</script>

<style>
._vue-flash-msg-body__content h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
  font-size: 1.1rem;
}
._vue-flash-msg-body__content p {
  color: #fff !important;
}
._vue-flash-msg-body ._vue-flash-msg-body__icon {
  background-color: #fff0 !important;
}
</style>
