import AppStroage from './UserAppStroage'
class User {
    login(data) {
        AppStroage.store(data);
    }
    loggedIn() {
        return AppStroage.logged();
    }
    orderLogin() {
        let status = localStorage.getItem('place_order');
        if (!status) {
            return false;
        }
        return status;
    }
    name() {
        if (this.loggedIn()) {
            if (AppStroage.getUser()) {
                return AppStroage.getUser()['name'];
            } else {
                return "";
            }
        }
    }
    profile() {
        if (this.loggedIn()) {
            if (AppStroage.getUser()) {
                return AppStroage.getUser()['profile'];
            } else {
                return "";
            }
        }
    }
    logout() {
        axios.get("/user/logout").then(res => {
            AppStroage.clear();
            window.location = laravel.baseurl + '/user/login'
        }).catch(error => console.log(error));
    }
}

export default User = new User();
