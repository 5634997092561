import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)

let cart = window.localStorage.getItem('cart');
let cartCount = window.localStorage.getItem('cartCount');

export default new Vuex.Store({
    state: {
        cart: cart ? JSON.parse(cart) : [],
        cartCount: cartCount ? parseInt(cartCount) : 0,
    },
    mutations: {
        addToCart(state, item) {
            if (item.pro_type == "single") {
                let found = state.cart.find(product => product.slug == item.slug);
                if (found) {
                    found.qty = found.qty + item.qty;
                    found.totalPrice = found.qty * found.amount;
                } else {
                    state.cart.push(item);
                    Vue.set(item, 'qty', item.qty);
                    Vue.set(item, 'totalPrice', (item.amount * item.qty));
                }
            } else {
                let found = state.cart.find(product => (product.slug == item.slug && product.size_id == item.size_id));
                if (found) {
                    found.qty = found.qty + item.qty;
                    found.totalPrice = found.qty * found.amount;
                } else {
                    state.cart.push(item);
                    Vue.set(item, 'qty', item.qty);
                    Vue.set(item, 'totalPrice', (item.amount * item.qty));
                }
            }
            state.cartCount = Object.keys(state.cart).length;
            this.commit('saveCart');
        },
        removeFromCart(state, item) {
            let index = state.cart.indexOf(item);

            if (index > -1) {
                state.cart.splice(index, 1);
            }
            let total = 0;
            for (let item of state.cart) {
                total += item.qty;
            }
            state.cartCount = total;
            this.commit('saveCart');
        },
        emptyToCart(state) {
            state.cart = [];
            state.cartCount = 0;
            localStorage.removeItem('cart')
            localStorage.removeItem('cartCount')
        },
        saveCart(state) {
            window.localStorage.setItem('cart', JSON.stringify(state.cart));
            window.localStorage.setItem('cartCount', state.cartCount);
        }
    }
});
