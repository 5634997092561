import Vue from 'vue';
import 'aos/dist/aos.css'
import AOS from 'aos';
window.AOS = AOS;

// ===============Login helpers=============
import User from "./../UserLogin/User";
window.User = User;

// ===============Pagination===============
import Pagination from './../../components/elements/Pagination'
Vue.component('Pagination', Pagination)

// ===============VueLazyload===============
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
    listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend'],
    // error: laravel.baseurl + '/public/assets/frontend/images/404.gif',
    // loading: laravel.baseurl + '/public/assets/frontend/images/lazy.gif',
})

// ===============Flash Message===============
import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);

// ===============Spinner===============
import Loader from './../../components/elements/FrontendLoader'
import Spinner from './../../components/elements/FrontendSpinner'
import FullLoader from './../../components/elements/Loader'
Vue.component('Spinner', Spinner)
Vue.component('FullLoader', FullLoader)
Vue.component('Loader', Loader)

// ===============Breadcrumbs from vuex===============
import breadcrumbs from "../../vuex/breadcrumbs_frontend";
window.frontBreadcrumbs = breadcrumbs;

// ===============Simple Vue Validator===============
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
window.Validator = Validator;
Vue.use(SimpleVueValidation);


import VueSlickCarousel from "vue-slick-carousel";
window.VueSlickCarousel = VueSlickCarousel;
Vue.component('VueSlickCarousel', VueSlickCarousel)
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import ProductZoomer from "vue-product-zoomer";
Vue.use(ProductZoomer);