Vue.mixin({
    methods: {
        // =================  get paginate data =================
        get_paginate_data(url) {
            try {
                this.$root.spinner = true;
                axios
                    .get(url)
                    .then(res => {
                        this.table['datas'] = res.data.data;
                        this.table['meta'] = res.data.meta;
                        this.table['links'] = res.data.links;
                    })
                    .catch(error => console.log(error))
                    .then(alw => setTimeout(() => (this.$root.spinner = false), 200));
            } catch (e) {
                return e.response;
            }
        },

        // =================  get single data =================
        get_data(url, dataVar, paramsData = null) {
            //this.$root.spinner = true;
            axios.get("/" + url, { params: paramsData })
                .then(res => (this.datas[dataVar] = res.data))
                .catch(error => console.log(error))
                .then(alw => setTimeout(() => (this.$root.spinner = false), 200));
        },

        // =================  get all data =================
        apiReq(url, dataVar = null, loader = false, leftSpinner = false) {
            try {
                this.$root.loader = loader ? true : false;
                this.$root.spinner = true;
                axios
                    .get("/" + url)
                    .then(res => {
                        this.datas[dataVar] = res.data;
                    })
                    .catch(error => console.log(error))
                    .then(alw => {
                        setTimeout(() => (this.$root.spinner = false), 100);
                        setTimeout(() => (this.$root.loader = false), 100);
                    });
            } catch (e) {
                return e.response;
            }
        },

        // =================user info=================
        userInfo() {
            this.logged.name = User.name();
            this.logged.profile = User.profile();
        },

    }
});
